import './Nav.scss';
import React, { useContext } from 'react';
import {
    FiCreditCard,
    FiHeadphones,
    FiHome,
    FiLogOut,
    FiTool,
    FiUser,
    FiUsers
} from 'react-icons/fi';
import { RouterContext } from '../../contexts/RouterContext';

type NavProps = {
    user: any;
    route: string;
    logout: Function;
};
export const Nav: React.FC<NavProps> = ({ route, logout, user }) => {
    const { push } = useContext(RouterContext);
    return (
        <nav id="nav">
            <ul>
                <li>
                    <button onClick={() => push('/')} disabled={route === '/'}>
                        <FiHome />
                    </button>
                </li>
                <li>
                    <button onClick={() => push('/player')} disabled={route === '/player'}>
                        <FiHeadphones />
                    </button>
                </li>
                <li>
                    <button onClick={() => push('/profile')} disabled={route === '/profile'}>
                        <FiUser />
                    </button>
                </li>
                <li>
                    <button onClick={() => push('/characters')} disabled={route === '/characters'}>
                        <FiUsers />
                    </button>
                </li>
                <li>
                    <button onClick={() => push('/card-game')} disabled={route === '/card-game'}>
                        <FiCreditCard />
                    </button>
                </li>
                {user?.admin && (
                    <li>
                        <button onClick={() => push('/admin')} disabled={route === '/admin'}>
                            <FiTool />
                        </button>
                    </li>
                )}
                <li>
                    <button className="logout" onClick={() => logout()}>
                        <FiLogOut />
                    </button>
                </li>
            </ul>
        </nav>
    );
};
