import './FormPanel.scss';
import React from 'react';
import { FiX } from 'react-icons/fi';

type FormPanelProps = {
    close?: Function;
};

export const FormPanel: React.FC<FormPanelProps> = ({ children, close }) => (
    <div className="form-panel scrollable">
        {close && (
            <button className={'close'} onClick={() => close()}>
                <FiX size="25px" />
            </button>
        )}
        {children}
    </div>
);
