import './Modal.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { FiX } from 'react-icons/fi';
import cn from 'classnames';

export const Modal: React.FC<{ className?: string; close: any }> = ({
    children,
    className,
    close
}) =>
    ReactDOM.createPortal(
        <div className={cn('modal', className)}>
            <button className="close" onClick={close}>
                <FiX />
            </button>
            {children}
        </div>,
        document.getElementById('modal-wrapper')
    );
