import React, { useState } from 'react';
import { FormPanel } from '../../components/FormPanel/FormPanel';
import { serializeForm } from '../../../helpers/serializeForm';

type ProfileProps = {
    user: any;
    setUser: Function;
};
export const Profile: React.FC<ProfileProps> = ({ user, setUser }) => {
    const [email, setEmail] = useState(user.email);
    const [password, setPassword] = useState('');
    return (
        <div id="view-profile">
            <FormPanel>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        const data = serializeForm(e.currentTarget);
                        console.log(data);
                        if (!data.password) delete data.password;
                        if (!data.email) delete data.email;
                        console.log(data);
                        if (Object.keys(data).length) {
                            fetch('/api/user', {
                                method: 'PUT',
                                headers: {
                                    'content-type': 'application/json'
                                },
                                body: JSON.stringify(data)
                            });
                        }
                    }}
                >
                    <div className="form-field">
                        <label htmlFor="profile-email">E-mail</label>
                        <input
                            name="email"
                            type="email"
                            id="profile-email"
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                        />
                    </div>
                    <div className="form-field no-clear m-l">
                        <label htmlFor="profile-password">Mot de passe</label>
                        <input
                            name="password"
                            type="password"
                            id="profile-password"
                            value={password}
                            onChange={(e) => setPassword(e.currentTarget.value)}
                        />
                    </div>
                    <div className="form-field no-clear m-l m-t">
                        <input type="submit" value="Modifier" />
                    </div>
                </form>
            </FormPanel>
        </div>
    );
};
