import './Player.scss';
import React, { useEffect, useRef, useState } from 'react';
import type { Socket } from 'socket.io';
import {
    playlistClientActions,
    playlistServerActions,
    playlistsServerActions,
    roomClientActions,
    rooms
} from '../../../wsActions';
import { Playlists } from './components/Playlists/Playlists';
import { PlayerControls } from './components/PlayerControls/PlayerControls';
import { Playlist } from './components/Playlist/Playlist';
import { FormPanel } from '../../components/FormPanel/FormPanel';
import { PlaylistCreate } from '../../components/forms/PlaylistCreate/PlaylistCreate';
import { PlaylistEdit } from '../../components/forms/PlaylistEdit/PlaylistEdit';

const shuffleArray = (arr) =>
    [...arr].reduceRight(
        (res, _, __, s) => (res.push(s.splice(0 | (Math.random() * s.length), 1)[0]), res),
        []
    );

export const Player: React.FC<{ socket: Socket; user: any }> = ({ socket, user }) => {
    const [playlist, setPlaylist] = useState({
        playlist: null,
        playlistIndex: 0,
        isPlaying: false
    });
    const [dbPlaylists, setDbPlaylists] = useState([]);
    const [time, setTime] = useState(0);
    const [shuffle, setShuffle] = useState(false);
    const interval = useRef(null);
    const [displaySongForm, setDisplaySongForm] = useState(null);
    const [displayCreatePlaylistForm, setDisplayCreatePlaylistForm] = useState(false);
    useEffect(() => {
        socket.emit(roomClientActions.join, rooms.playlist);

        const onUpdate = function ({ trackStartTime, currentTime, ...data }: any) {
            setPlaylist(data);
            clearInterval(interval.current);
            if (data.isPlaying) {
                const clientServerDiff = currentTime - Date.now();
                setTime(currentTime - trackStartTime);
                interval.current = setInterval(() => {
                    setTime(Date.now() + clientServerDiff - trackStartTime);
                }, 200);
            }
        };

        socket.on(playlistServerActions.update, onUpdate);

        socket.on(playlistsServerActions.set, setDbPlaylists);

        return () => {
            socket.off(playlistServerActions.update, onUpdate);
            socket.off(playlistsServerActions.set, setDbPlaylists);
            socket.emit(roomClientActions.leave, rooms.playlist);
        };
    }, []);
    return (
        <div id="view-player">
            <Playlists
                shuffle={shuffle}
                setShuffle={setShuffle}
                playlists={dbPlaylists}
                displaySongForm={displaySongForm}
                setDisplaySongForm={setDisplaySongForm}
                displayCreatePlaylistForm={displayCreatePlaylistForm}
                setDisplayCreatePlaylistForm={setDisplayCreatePlaylistForm}
                deletePlaylist={(uid) => socket.emit(playlistClientActions.delete, uid)}
                user={user}
                setActivePlaylist={(playlist, _shuffle) =>
                    socket.emit(
                        playlistClientActions.setPlaylist,
                        _shuffle
                            ? {
                                  ...playlist,
                                  songs: shuffleArray(playlist.songs)
                              }
                            : playlist
                    )
                }
            />

            <Playlist
                playlist={playlist.playlist}
                time={time}
                activeIndex={playlist.playlistIndex}
                setActiveIndex={(i) => socket.emit(playlistClientActions.setPlaylistIndex, i)}
            />

            {(displaySongForm || displayCreatePlaylistForm) && (
                <FormPanel
                    close={() => {
                        setDisplayCreatePlaylistForm(false);
                        setDisplaySongForm(false);
                    }}
                >
                    {displayCreatePlaylistForm && (
                        <PlaylistCreate
                            onSubmit={(payload) =>
                                socket.emit(playlistClientActions.create, payload)
                            }
                        />
                    )}

                    {displaySongForm && (
                        <PlaylistEdit
                            editPlaylist={(payload) =>
                                socket.emit(playlistClientActions.edit, payload)
                            }
                            addSong={(payload) =>
                                socket.emit(playlistClientActions.addSong, payload)
                            }
                            deleteSong={(uid) => socket.emit(playlistClientActions.deleteSong, uid)}
                            playlist={dbPlaylists.find(({ uid }) => uid === displaySongForm)}
                        />
                    )}
                </FormPanel>
            )}

            <PlayerControls
                isPlaying={playlist.isPlaying}
                play={() => socket.emit(playlistClientActions.play)}
                pause={() => socket.emit(playlistClientActions.pause)}
                time={time}
                song={playlist.playlist?.songs[playlist.playlistIndex]}
                prev={
                    playlist?.playlistIndex > 0 && playlist.playlist?.songs.length > 1
                        ? () => socket.emit(playlistClientActions.previous)
                        : null
                }
                next={
                    playlist?.playlistIndex < playlist.playlist?.songs.length - 1
                        ? () => socket.emit(playlistClientActions.next)
                        : null
                }
            />
        </div>
    );
};
