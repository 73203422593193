import './Tooltip.scss';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import {
    Direction,
    DirectionX,
    DirectionY,
    getAbsolutePosition
} from '../../../helpers/dom/getAbsolutePosition';
import cn from 'classnames';

const tooltips = [];

export const Tooltip: React.FC<{
    content: any;
    direction?: Direction;
    contentSizedByTrigger?: boolean;
    className?: string;
    Component: any;
    [p: string]: any;
}> = ({
    children,
    content,
    Component = 'div',
    direction = [DirectionX.LeftInner, DirectionY.Top],
    className,
    contentSizedByTrigger = false,
    ...p
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const [style, setStyle] = useState(null);
    const triggerRef = useRef(null);
    const absoluteRef = useRef(null);
    const timeout = useRef(null);

    useEffect(() => {
        if (isVisible) {
            setStyle(
                getAbsolutePosition(triggerRef, absoluteRef, direction, contentSizedByTrigger)
            );
        }
    }, [isVisible]);

    useEffect(() => {
        tooltips.push({ timeout, setIsVisible });
        triggerRef.current.addEventListener('mouseover', () => {
            clearTimeout(timeout.current);
            setIsVisible(true);
            tooltips
                .filter((t) => t.timeout.current !== timeout.current)
                .forEach((t) => {
                    clearTimeout(t.timeout.current);
                    t.setIsVisible(false);
                });
        });
        triggerRef.current.addEventListener('mouseout', () => {
            timeout.current = setTimeout(() => setIsVisible(false), 300);
        });

        return () => {
            clearTimeout(timeout.current);
            tooltips.splice(
                tooltips.findIndex((t) => t.timeout.current === timeout.current),
                1
            );
        };
    }, []);
    return (
        <>
            <Component {...p} className={cn('tooltip', className)} ref={triggerRef}>
                {children}
            </Component>
            {isVisible &&
                ReactDOM.createPortal(
                    <div
                        className="tooltip-content"
                        style={style}
                        ref={absoluteRef}
                        onMouseOver={() => {
                            setIsVisible(true);
                            clearTimeout(timeout.current);
                        }}
                        onMouseOut={() => {
                            timeout.current = setTimeout(() => setIsVisible(false), 300);
                        }}
                    >
                        {content}
                    </div>,
                    document.body
                )}
        </>
    );
};
