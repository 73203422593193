import './Character.scss';
import React, { useEffect, useState } from 'react';
import { ModalCraft } from '../../components/Modal/ModalCraft/ModalCraft';
import update from 'immutability-helper';
import { FiTrash } from 'react-icons/fi';

export const Character: React.FC<{ characterId: string }> = ({ characterId }) => {
    const [character, setCharacter] = useState(null);
    const [crafts, setCrafts] = useState([]);
    const [modalCrafting, setModalCrafting] = useState(false);
    useEffect(() => {
        fetch(`/api/character/${characterId}`)
            .then((r) => r.json())
            .then((c) => setCharacter(c));
        fetch(`/api/character/${characterId}/crafts`)
            .then((r) => r.json())
            .then((c) => setCrafts(c));
    }, []);

    if (!character) return null;

    const updateCrafts = async (e) => {
        const i = parseInt(e.currentTarget.id.replace(/[a-zA-Z-_]*/, ''));
        const v = e.currentTarget.value;
        await fetch(`/api/crafting/craft/${crafts[i].uid}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                craftedValue: parseInt(v)
            })
        });
        setCrafts(
            update(crafts, {
                [i]: {
                    craftedValue: {
                        $set: v
                    }
                }
            })
        );
    };

    const deleteCraft = async (uid) => {
        await fetch(`/api/crafting/craft/${uid}`, {
            method: 'DELETE'
        });
        setCrafts(
            update(crafts, {
                $splice: [[crafts.findIndex((c) => c.uid === uid), 1]]
            })
        );
    };

    return (
        <div id="character">
            <div className="character-info">
                <h2>{character.name}</h2>

                <button className="button" onClick={() => setModalCrafting(true)}>
                    Craft
                </button>

                <br />

                <h2>Crafts</h2>

                <ul className="crafts">
                    {crafts.map((c, i) => (
                        <li key={i}>
                            {c.name}{' '}
                            <input
                                id={`craft-range-${i}`}
                                type="range"
                                disabled
                                value={c.craftedValue}
                                max={c.craftingValue}
                                onChange={updateCrafts}
                            />
                            <input
                                id={`craft-${i}`}
                                type="number"
                                value={c.craftedValue}
                                onChange={updateCrafts}
                            />
                            / {c.craftingValue}
                            <button className={'delete'} onClick={() => deleteCraft(c.uid)}>
                                <FiTrash />
                            </button>
                        </li>
                    ))}
                </ul>
            </div>

            {modalCrafting && (
                <ModalCraft
                    characterUid={character.uid}
                    close={() => setModalCrafting(false)}
                    addCraft={(c) => setCrafts([...crafts, c])}
                />
            )}
        </div>
    );
};
