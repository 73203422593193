export const rooms = {
    playlist: 'playlist',
    cardGame: 'cardGame'
};

export const roomClientActions = {
    join: 'room/join',
    leave: 'room/leave'
};

export const playlistClientActions = {
    makeBotJoin: 'playlist/makeBotJoin',
    makeBotLeave: 'playlist/makeBotLeave',
    create: 'playlist/create',
    edit: 'playlist/edit',
    delete: 'playlist/delete',
    addSong: 'playlist/addSong',
    deleteSong: 'playlist/deleteSong',
    play: 'playlist/play',
    pause: 'playlist/pause',
    previous: 'playlist/previous',
    next: 'playlist/next',
    setPlaylistIndex: 'playlist/setPlaylistIndex',
    setPlaylist: 'playlist/setPlaylist',
    setPlaylistFromDb: 'playlist/setPlaylistFromDb'
};

export const playlistServerActions = {
    update: 'playlist/update'
};

export const playlistsServerActions = {
    set: 'playlists/set'
};


export const cardGameClientActions = {
    join: 'cardGame/join',
    create: 'cardGame/create',
    get: 'cardGame/get',
    play: 'cardGame/play',
    check: 'cardGame/check'
}

export const cardGameServerActions = {
    list: 'cardGame/list',
    created: 'cardGame/created',
    added: 'cardGame/added',
    update: 'cardGame/update'
}
