import './Playlists.scss';
import React, { useState } from 'react';
import { Playlist } from '../../../../../types/playlistTypes';
import { FiEdit, FiMaximize, FiMinus, FiPlay, FiPlus, FiShuffle, FiTrash } from 'react-icons/fi';
import { ModalConfirm } from '../../../../components/Modal/ModalConfirm';
import { Tooltip } from '../../../../components/Tooltip/Tooltip';
import { DirectionX, DirectionY } from '../../../../../helpers/dom/getAbsolutePosition';
import cn from 'classnames';

type PlaylistsProps = {
    playlists: Playlist[];
    setActivePlaylist: Function;
    setShuffle: Function;
    shuffle: boolean;
    displaySongForm: string | boolean;
    displayCreatePlaylistForm: boolean;
    setDisplaySongForm: Function;
    setDisplayCreatePlaylistForm: Function;
    user: any;
    deletePlaylist: Function;
};
export const Playlists: React.FC<PlaylistsProps> = ({
    playlists,
    setActivePlaylist,
    displaySongForm,
    displayCreatePlaylistForm,
    setDisplaySongForm,
    setDisplayCreatePlaylistForm,
    user,
    deletePlaylist
}) => {
    const [collapsed, setCollapsed] = useState(false);

    const toggleSongForm = (uid) => {
        setDisplaySongForm(displaySongForm === uid ? null : uid);
        setDisplayCreatePlaylistForm(false);
    };

    const [deletePlaylistObj, setDeletePlaylistObj] = useState(null);

    return (
        <div className={cn('playlists scrollable', { collapsed })}>
            <h2>
                Playlists
                <Tooltip
                    Component="button"
                    direction={[DirectionX.Center, DirectionY.Top]}
                    content={<>Ajouter une playlist</>}
                    className="add-playlist top-button"
                    onClick={() => {
                        setDisplayCreatePlaylistForm(!displayCreatePlaylistForm);
                        setDisplaySongForm(false);
                    }}
                >
                    <FiPlus size="15px" />
                </Tooltip>
            </h2>
            <button className="collapse-button top-button" onClick={() => setCollapsed(!collapsed)}>
                {collapsed ? <FiMaximize /> : <FiMinus />}
            </button>

            <ul>
                {playlists?.map((playlist) => (
                    <li key={playlist.name}>
                        <span>{playlist.name}</span>
                        {(user.admin || user.email === playlist.owner) && (
                            <Tooltip
                                direction={[DirectionX.Center, DirectionY.Top]}
                                Component="button"
                                content={<>Supprimer la playlist</>}
                                onClick={() => setDeletePlaylistObj(playlist)}
                            >
                                <FiTrash />
                            </Tooltip>
                        )}
                        {(user.email === playlist.owner || playlist.shared || user.admin) && (
                            <Tooltip
                                Component="button"
                                direction={[DirectionX.Center, DirectionY.Top]}
                                onClick={() => {
                                    setCollapsed(true);
                                    toggleSongForm(playlist.uid);
                                }}
                                content={<>Editer la playlist</>}
                            >
                                <FiEdit />
                            </Tooltip>
                        )}
                        <Tooltip
                            Component="button"
                            direction={[DirectionX.Center, DirectionY.Top]}
                            onClick={() => setActivePlaylist(playlist, true)}
                            content={<>Lancer la playlist en shuffle</>}
                        >
                            <FiShuffle />
                        </Tooltip>
                        <Tooltip
                            Component="button"
                            direction={[DirectionX.Center, DirectionY.Top]}
                            onClick={() => setActivePlaylist(playlist)}
                            content={<>Lancer la playlist</>}
                        >
                            <FiPlay />
                        </Tooltip>
                    </li>
                ))}
            </ul>

            {deletePlaylistObj && (
                <ModalConfirm
                    title="Suppression"
                    text={
                        <>
                            Voulez vous supprimer la playlist <b>{deletePlaylistObj.name} ?</b>
                        </>
                    }
                    close={() => setDeletePlaylistObj(null)}
                    confirm={() => deletePlaylist(deletePlaylistObj.uid)}
                />
            )}
        </div>
    );
};
