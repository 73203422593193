import './PlaylistEdit.scss';
import React, { useEffect, useState } from 'react';
import { Playlist } from '../../../../types/playlistTypes';
import { FiTrash } from 'react-icons/fi';
import { ModalConfirm } from '../../Modal/ModalConfirm';

type SongFormProps = {
    editPlaylist: Function;
    addSong: Function;
    deleteSong: Function;
    playlist: Playlist;
    url?: string;
};

export const PlaylistEdit: React.FC<SongFormProps> = ({
    editPlaylist,
    addSong,
    deleteSong,
    playlist,
    url: _url
}) => {
    const [url, setUrl] = useState(_url);
    const [name, setName] = useState(playlist.name);

    const [deleteSongObj, setDeleteSongObj] = useState(null);

    useEffect(() => {
        setName(playlist.name);
    }, [playlist]);
    return (
        <div className="playlist-edit">
            <h2>Edition de la playlist - {playlist.name}</h2>
            <form
                onSubmit={(e: any) => {
                    e.preventDefault();
                    editPlaylist({ uid: playlist.uid, name });
                }}
            >
                <div className="form-field">
                    <label htmlFor="playlist-name">Nom</label>
                    <input
                        type="text"
                        id="playlist-name"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.currentTarget.value)}
                    />
                </div>
                <div className="form-field no-clear m-t">
                    <input type="submit" value="Editer" />
                </div>
            </form>
            <ul className="scrollable">
                {playlist.songs.map((s) => (
                    <li key={s.uid}>
                        {s.title}
                        <button className="trash" onClick={() => setDeleteSongObj(s)}>
                            <FiTrash />
                        </button>
                    </li>
                ))}
            </ul>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    addSong({ url, playlistUid: playlist.uid });
                }}
            >
                <div className="form-field">
                    <label htmlFor="song-url">URL Youtube</label>
                    <input
                        type="text"
                        id="song-url"
                        name="url"
                        onChange={(e: any) => setUrl(e.currentTarget.value)}
                    />
                </div>
                <div className="form-field no-clear m-t">
                    <input type="submit" value="Ajouter" />
                </div>
            </form>

            {deleteSongObj && (
                <ModalConfirm
                    title="Suppression"
                    text={
                        <>
                            Voulez vous supprimer la chanson <b>{deleteSongObj.title} ?</b>
                        </>
                    }
                    close={() => setDeleteSongObj(null)}
                    confirm={() => deleteSong(deleteSongObj.uid)}
                />
            )}
        </div>
    );
};
