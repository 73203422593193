import './PlayerControls.scss';
import React from 'react';
import { FiPlay, FiPause, FiSkipForward, FiSkipBack } from 'react-icons/fi';
import cn from 'classnames';

type PlayerControlsProps = {
    isPlaying: boolean;
    play: Function;
    pause: Function;
    time: number;
    song?: any;
    prev?: Function;
    next?: Function;
};

export const PlayerControls: React.FC<PlayerControlsProps> = ({
    isPlaying,
    play,
    pause,
    time,
    song,
    prev,
    next
}) => (
    <div className={cn('player-controls', isPlaying && 'is-playing')}>
        <div className="buttons">
            {prev && (
                <button className="prev-button" onClick={() => prev()}>
                    <FiSkipBack size="25px" />
                </button>
            )}
            <button className="play-button" onClick={() => (isPlaying ? pause() : play())}>
                {isPlaying ? <FiPause size="50px" /> : <FiPlay size="50px" />}
            </button>
            {next && (
                <button className="next-button" onClick={() => next()}>
                    <FiSkipForward size="25px" />
                </button>
            )}
        </div>
        <div className="seek-bar">
            <div
                className="thumb"
                style={{ left: song ? `${(time / 1000 / song.duration) * 100}%` : 0 }}
            />
        </div>
    </div>
);
