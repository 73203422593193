import './Characters.scss';
import React, { useContext, useEffect, useState } from 'react';
import { RouterContext } from '../../contexts/RouterContext';

export const Characters = () => {
    const [characters, setCharacters] = useState([]);
    const { push } = useContext(RouterContext);

    useEffect(() => {
        fetch('/api/characters')
            .then((r) => r.json())
            .then((characters) => setCharacters(characters));
    }, []);
    return (
        <div id="characters">
            <div className="content">
                <h2>Personnages</h2>
                <ul>
                    {characters.map((c) => (
                        <li key={c.uid}>
                            <button onClick={() => push(`/character/${c.uid}`)}>{c.name}</button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
