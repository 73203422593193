import './App.scss';
import React from 'react';
import { Player } from './Player/Player';
import cookie from 'cookie';
import { Login } from './Login/Login';
import type { Socket } from 'socket.io';
import bgImage from 'url:../assets/images/bg.jpg';
import { Nav } from '../components/Nav/Nav';
import { RouterContext } from '../contexts/RouterContext';
import { Profile } from './Profile/Profile';
import { CardGames } from './CardGames/CardGames';
import { CardGame } from './CardGame/CardGame';
import { Admin } from './Admin/Admin';
import { Character } from './Character/Character';
import { Characters } from './Characters/Characters';

export class App extends React.Component<
    {},
    { route: string; user: any; loading: boolean; socket: Socket }
> {
    form: React.RefObject<HTMLFormElement>;
    constructor(props) {
        super(props);
        this.state = {
            route: window.location.pathname,
            user: null,
            loading: true,
            socket: null
        };
    }

    componentDidMount() {
        const cookies = cookie.parse(document.cookie);

        if (cookies.token) {
            fetch('/api/token_login')
                .then((r) => r.json())
                .then((user) => this.setState({ user, loading: false }));
        } else {
            this.setState({ loading: false });
        }

        window.addEventListener('popstate', () => {
            this.setState({
                route: window.location.pathname
            });
        });
    }

    componentDidUpdate(
        prevProps: Readonly<{}>,
        prevState: Readonly<{ route: string; user: any; loading: boolean }>
    ) {
        if (!prevState.user && this.state.user) {
            const socket = io();
            socket.on('connect', () => {
                //@ts-ignore
                this.setState({ socket });
            });
        }
    }

    render() {
        return (
            <RouterContext.Provider
                value={{
                    push: (route) => {
                        history.pushState(null, null, route);
                        this.setState({ route });
                    }
                }}
            >
                <div className="app">
                    <img src={bgImage} alt="" />
                    <a
                        className="credits"
                        href="https://www.artstation.com/dufresnejo"
                        target="_blank"
                    >
                        Illustration par Johnathan Dufresne
                    </a>
                    {this.state.user && (
                        <Nav
                            user={this.state.user}
                            route={this.state.route}
                            logout={async () => {
                                await fetch('/api/logout');
                                this.setState({ user: null });
                            }}
                        />
                    )}
                    <main>{this.renderRoute(this.state.route)}</main>
                </div>
            </RouterContext.Provider>
        );
    }

    renderRoute(route: string) {
        if (this.state.loading) return <>Loading...</>;

        if (!this.state.user) return <Login setUser={this.setUser} />;
        if (!this.state.socket) return <>Loading...</>;

        const _route = route.split('?');
        const params: { [k: string]: string } = {};
        if (_route[1]) {
            _route[1].split('&').forEach((pairs) => {
                const _pairs = pairs.split('=');
                params[_pairs[0]] = _pairs[1];
            });
        }

        const routeParts = _route[0].replace(/^\/|\/$/, '').split('/');
        console.log(routeParts);
        switch (routeParts[0]) {
            case 'admin':
                return <Admin socket={this.state.socket} user={this.state.user} />;
            case 'login':
                return <Login setUser={this.setUser} />;

            case 'player':
                return <Player socket={this.state.socket} user={this.state.user} />;

            case 'profile':
                return <Profile user={this.state.user} setUser={this.setUser} />;
            case 'card-game':
                return routeParts.length > 1 ? (
                    <CardGame god={params.god} socket={this.state.socket} gameId={routeParts[1]} />
                ) : (
                    <CardGames socket={this.state.socket} user={this.state.user} />
                );
            case 'characters':
                return <Characters />;
            case 'character':
                return <Character characterId={routeParts[1]} />;
        }
    }

    setUser = (user) => this.setState({ user });
}
