import React, { useContext, useEffect, useState } from 'react';
import {
    cardGameServerActions,
    cardGameClientActions,
    roomClientActions,
    rooms
} from '../../../wsActions';
import { Socket } from 'socket.io';
import { RouterContext } from '../../contexts/RouterContext';

export const CardGames: React.FC<{ socket: Socket; user: any }> = ({ socket }) => {
    const { push } = useContext(RouterContext);
    const [games, setGames] = useState([]);
    const [god, setGod] = useState('Bachanar');

    useEffect(() => {
        socket.emit(roomClientActions.join, rooms.cardGame);
        socket.emit(cardGameClientActions.get);
        socket.on(cardGameServerActions.created, (game) => push(`card-game/${game.id}?god=${god}`));
        socket.on(cardGameServerActions.added, (game) => {
            setGames([...games, game.id]);
        });
        socket.on(cardGameServerActions.list, (games) => {
            setGames(games);
        });

        if (window.localStorage.god) setGod(window.localStorage.god);

        return () => {
            socket.emit(roomClientActions.leave, rooms.cardGame);
        };
    }, []);

    useEffect(() => {
        window.localStorage.setItem('god', god);
    }, [god]);

    return (
        <div id="card-games">
            <select value={god} onChange={(e) => setGod(e.currentTarget.value)}>
                <option>Bachanar</option>
                {/*<option>Bartholo</option>*/}
                {/*<option>Foelia</option>*/}
                <option>Jarrus</option>
                {/*<option>Koreïa</option>*/}
                {/*<option>Lucifa</option>*/}
                {/*<option>Obadiah</option>*/}
                <option>Orthus</option>
                {/*<option>Theia</option>*/}
                {/*<option>Vephys</option>*/}
            </select>
            <button onClick={() => socket.emit(cardGameClientActions.create, { god })}>
                Create
            </button>

            <ul>
                {games.map((id) => (
                    <li key={id}>
                        <button onClick={() => push(`/card-game/${id}?god=${god}`)}>{id}</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};
