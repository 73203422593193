import './CardGame.scss';
import React, { useEffect, useState } from 'react';
import type { Socket } from 'socket.io';
import {
    cardGameClientActions,
    cardGameServerActions,
    roomClientActions,
    rooms
} from '../../../wsActions';
import { Card } from './Card';

export const CardGame: React.FC<{ socket: Socket; gameId: string; god: string }> = ({
    socket,
    gameId,
    god
}) => {
    const [game, setGame] = useState(null);
    const [wins, setWins] = useState(0);
    const [losses, setLosses] = useState(0);
    const [message, setMessage] = useState(null);
    const [firstRender, setFirstRender] = useState(true);
    const [ended, setEnded] = useState(false);

    useEffect(() => {
        socket.emit(cardGameClientActions.join, { gameId, god });

        const onUpdate = (game) => {
            setGame(game);
            if (game.wins !== wins) setWins(game.wins);
            if (game.opponentWins !== losses) setLosses(game.opponentWins);
            if (firstRender) setTimeout(() => setFirstRender(false), 200);
            if (game.ended) {
                setEnded(true);
                endGame();
            }
        };
        const endGame = () => {
            socket.off(cardGameServerActions.update, onUpdate);
            socket.emit(roomClientActions.leave, rooms.cardGame + '/' + gameId);
        };

        socket.on(cardGameServerActions.update, onUpdate);
        return endGame;
    }, []);

    useEffect(() => {
        if (!firstRender) {
            setMessage('You win');
            setTimeout(() => setMessage(null), 3000);
        }
    }, [wins]);

    useEffect(() => {
        if (!firstRender) {
            setMessage('You lose');
            setTimeout(() => setMessage(null), 3000);
        }
    }, [losses]);

    if (!game) return <>Loading</>;
    return (
        <div id="card-game">
            <header className="controls">
                {message && <h1>{message}</h1>}
                {game.playerTurn && (
                    <>
                        <div className="player-turn" />
                        {game.playedCards.length > 0 && (
                            <button
                                className="button"
                                onClick={() =>
                                    socket.emit(cardGameClientActions.check, { gameId: game.id })
                                }
                            >
                                Check
                            </button>
                        )}
                    </>
                )}

                <div>Victoires: {wins}</div>

                <div>Défaites: {losses}</div>

                {ended && (
                    <div>
                        {wins > losses
                            ? 'Vous avez gagné'
                            : losses > wins
                            ? 'Vous avez perdu'
                            : 'Egalité'}
                    </div>
                )}
            </header>

            <ul className="opponent">
                {game?.opponentPlayedCards?.map((c, i) => (
                    <Card key={i} {...c} />
                ))}
            </ul>
            <ul className="cards">
                {game?.playedCards?.map((c, i) => (
                    <Card key={i} {...c} />
                ))}
            </ul>

            <ul className="hand">
                {game.cards.map((c, i) => (
                    <Card
                        key={i}
                        onClick={
                            game.playerTurn
                                ? () =>
                                      socket.emit(cardGameClientActions.play, {
                                          gameId: game.id,
                                          cardIndex: i
                                      })
                                : null
                        }
                        {...c}
                    />
                ))}
            </ul>
        </div>
    );
};
