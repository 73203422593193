import './scss/global.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './views/App';

const checkIo = () => {
    window.requestAnimationFrame(() => {
        if (typeof io === 'undefined') return checkIo();
        ReactDOM.render(<App />, document.getElementById('app'));
    });
};

checkIo();
