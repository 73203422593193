import './PlaylistCreate.scss';
import React, { useState } from 'react';

type PlaylistFormProps = {
    onSubmit: Function;
    name?: string;
    shared?: boolean;
};

export const PlaylistCreate: React.FC<PlaylistFormProps> = ({
    onSubmit,
    name: _name,
    shared: _shared
}) => {
    const [name, setName] = useState(_name);
    const [shared, setShared] = useState(_shared || true);
    return (
        <div className="playlist-create">
            <h2>Créer une playlist</h2>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit({ name, shared });
                }}
            >
                <div className="form-field">
                    <label htmlFor="playlist-name">Nom</label>
                    <input
                        id="playlist-name"
                        type="text"
                        name="name"
                        onChange={(e: any) => setName(e.currentTarget.value)}
                    />
                </div>
                <div className="form-field no-clear m-l">
                    <input
                        type="checkbox"
                        id="playlist-share"
                        checked={shared}
                        onChange={null /*(e: any) => setShared(e.currentTarget.checked)*/}
                    />
                    <label htmlFor="playlist-share">
                        Permettre aux autres d'éditer cette playlist
                    </label>
                </div>
                <div className="form-field no-clear m-l m-t">
                    <input type="submit" />
                </div>
            </form>
        </div>
    );
};
