import './Card.scss';
import React from 'react';

import soldat from 'url:../../assets/images/cards/soldat.jpg';
import chevalier from 'url:../../assets/images/cards/chevalier.jpg';
import magicien from 'url:../../assets/images/cards/magicien.jpg';
import general from 'url:../../assets/images/cards/general.jpg';
import archimage from 'url:../../assets/images/cards/archimage.jpg';

const nameToImage = (name) => {
    switch(name) {
        case 'Soldat':
            return soldat;
        case 'Chevalier':
            return chevalier;
        case 'Magicien':
            return magicien;
        case 'Général':
            return general;
        case 'Archimage':
            return archimage;
    }
}

export const Card = ({ name, power, onClick}) => {
    return <li className="game-card" onClick={onClick}>
        <h2>{name}</h2>
        <span className="power">{power}</span>
        <img src={`${nameToImage(name)}`} alt={""} />
    </li>;
}
