import React from 'react';
import { Modal } from './Modal';

export const ModalConfirm = ({ title, text, close, confirm }) => (
    <Modal close={close}>
        <h2>{title}</h2>
        <p>{text}</p>
        <div className="bottom-buttons">
            <button className={'button light'} onClick={() => close()}>
                Annuler
            </button>
            <button
                className={'button'}
                onClick={async () => {
                    try {
                        await confirm();
                        close();
                    } catch (e) {}
                }}
            >
                Confirmer
            </button>
        </div>
    </Modal>
);
