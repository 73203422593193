import './ModalCraft.scss';
import React, { useEffect, useState } from 'react';
import { Modal } from '../Modal';

export const ModalCraft = ({ addCraft, characterUid, close }) => {
    const [recipes, setRecipes] = useState([]);
    const [category, setCategory] = useState(null);
    const [recipe, setRecipe] = useState(null);

    useEffect(() => {
        fetch(`/api/crafting/recipes`)
            .then((r) => r.json())
            .then((r) =>
                setRecipes(
                    r.reduce((acc, i) => {
                        const category =
                            acc.find((c) => c.type === i.type) ||
                            acc[acc.push({ type: i.type, recipes: [] }) - 1];
                        category.recipes.push(i);
                        return acc;
                    }, [])
                )
            );
    }, []);
    return (
        <Modal className="modal-craft" close={close}>
            <h2>Craft</h2>

            <form>
                <div className="form-field">
                    <label>Type</label>
                    <select onChange={(e) => setCategory(e.currentTarget.value)}>
                        <option value={null}>Select</option>
                        {recipes.map((c) => (
                            <option value={c.type} key={c.type}>
                                {c.type}
                            </option>
                        ))}
                    </select>
                </div>
                {category && (
                    <div className="form-field">
                        <label>Objet</label>
                        <select
                            onChange={(e) =>
                                setRecipe(
                                    recipes
                                        .find(({ type }) => type === category)
                                        .recipes.find(({ uid }) => uid === e.currentTarget.value)
                                )
                            }
                        >
                            <option value={null}>Select</option>
                            {recipes
                                .find(({ type }) => type === category)
                                .recipes.map((r) => (
                                    <option value={r.uid} key={r.uid}>
                                        {r.name}
                                    </option>
                                ))}
                        </select>
                    </div>
                )}
            </form>
            {recipe && (
                <div className="item">
                    <div>
                        <span>Durée</span> {recipe.craftingValue}
                    </div>
                    <h3>Ingrédients</h3>
                    <ul>
                        {recipe.ingredients.map((ingredient, i) => (
                            <li key={i}>
                                - {ingredient.name} {ingredient.amount && `(${ingredient.amount})`}
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            <div className="bottom-buttons">
                <button className={'button light'} onClick={() => close()}>
                    Annuler
                </button>
                <button
                    className={'button'}
                    disabled={!recipe}
                    onClick={async () => {
                        try {
                            const { uid } = await fetch('/api/crafting/craftItem', {
                                method: 'POST',
                                headers: {
                                    'content-type': 'application/json'
                                },
                                body: JSON.stringify({
                                    characterUid,
                                    craftingRecipeUid: recipe.uid
                                })
                            }).then((r) => r.json());
                            addCraft({
                                uid,
                                name: recipe.name,
                                craftedValue: 0,
                                craftingValue: recipe.craftingValue
                            });
                            close();
                        } catch (e) {}
                    }}
                >
                    Confirmer
                </button>
            </div>
        </Modal>
    );
};
