import React, { useEffect } from 'react';
import { playlistClientActions, roomClientActions, rooms } from '../../../wsActions';

export const Admin = ({ user, socket }) => {
    if (!user?.admin) return;

    useEffect(() => {
        socket.emit(roomClientActions.join, rooms.playlist);

        return () => {
            socket.emit(roomClientActions.leave, rooms.playlist);
        };
    }, []);
    return (
        <div id="admin">
            <h2>Bot</h2>
            <button
                className="button"
                onClick={() => socket.emit(playlistClientActions.makeBotJoin)}
            >
                Join
            </button>
            <button
                onClick={() => socket.emit(playlistClientActions.makeBotLeave)}
                className="button"
            >
                Leave
            </button>
        </div>
    );
};
