import './Login.scss';
import React, { useRef } from 'react';
export const Login: React.FC<{ setUser: Function }> = ({ setUser }) => {
    const form = useRef<HTMLFormElement>(null);

    return (
        <div id="view-login">
            <form
                ref={form}
                onSubmit={(e) => {
                    e.preventDefault();
                    const fd = new FormData(form.current);
                    const data = {};
                    fd.forEach((value, key) => (data[key] = value));
                    fetch('/api/login', {
                        method: 'POST',
                        headers: { 'content-type': 'application/json' },
                        body: JSON.stringify(data)
                    })
                        .then((r) => r.json())
                        .then((user) => setUser(user));
                }}
            >
                <h1>Connexion</h1>
                <div className="form-field">
                    <label htmlFor="login-email">E-Mail</label>
                    <input id="login-email" type="email" name="email" />
                </div>
                <div className="form-field">
                    <label htmlFor="login-password">Mot de passe</label>
                    <input type="password" name="password" autoComplete="current-password" />
                    <input type="submit" />
                </div>
            </form>
        </div>
    );
};
