import './Playlist.scss';
import React from 'react';

type PlaylistProps = {
    playlist: any;
    time: number;
    activeIndex: number;
    setActiveIndex: (i: number) => void;
};
export const Playlist: React.FC<PlaylistProps> = ({
    playlist,
    time,
    activeIndex,
    setActiveIndex
}) =>
    playlist?.songs?.length ? (
        <div className="playlist scrollable">
            <ul>
                {playlist?.songs.map(({ title, url, duration }, i) => (
                    <li key={url} className={activeIndex === i ? 'is-active' : null}>
                        <button onClick={() => setActiveIndex(i)}>
                            {title} {time && activeIndex === i ? `${time / 1000}/${duration}` : ''}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    ) : null;
